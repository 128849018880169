<template>
  <div>
    <div class="content_container">
      <VueCanvasPoster
        :widthPixels="1000"
        :painting="painting"
        @success="success"
        @fail="fail"
      ></VueCanvasPoster>
      <img :src="posterImg" />
    </div>
  </div>
</template>
<script>
import { VueCanvasPoster } from "vue-canvas-poster";
export default {
  components: {
    VueCanvasPoster,
  },
  data() {
    return {
      posterImg: "", //生成的海报
      painting: {
        width: "550px",
        height: "876px",
        background:
          "https://ddcz.oss-cn-beijing.aliyuncs.com/images/file-1587004539522R5oa.png",
        views: [
          {
            type: "image",
            url: "http://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIxbEZu0fBQOYlTLTpqtoezkNiaCBQ4ZXZAKhvPiaFAdI91vBsUkpiaDH4mp4qGvcVo2EaxRrp5fMia8w/132",
            css: {
              top: "20px",
              left: "50px",
              borderRadius: "40px",
              width: "80px",
              height: "80px",
            },
          },
          {
            type: "text",
            text: "智慧社区科技公司",
            css: {
              top: "48px",
              left: "160px",
              width: "360px",
              maxLines: 1,
              fontSize: "26px",
            },
          },
          {
            type: "qrcode",
            content:
              "https://zy.jishiyoo.com/m/card/group?key=5PCxqmV7oF3D0wx3WnbnPLRlbunt7A",
            css: {
              bottom: "50px",
              right: "26px",
              color: "#000",
              background: "#fff",
              width: "130px",
              height: "130px",
              borderWidth: "10px",
              borderColor: "#fff",
            },
          },
        ],
      },
    };
  },
  methods: {
    success(src) {
      this.posterImg = src;
      console.log(src);
    },
    fail(err) {
      alert(err);
    },
  },
};
</script>
<style>
img {
  width: 100%;
  height: 100%;
}
</style>